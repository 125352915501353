import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import img from '../images/web.svg';
import img2 from '../images/app.svg';
import img3 from '../images/hosting.svg';
import img4 from '../images/consultation.svg';
import img_appointment from '../images/new/appointment.svg';
import img_customer_support from '../images/new/customer_support.svg';
import img_survey from '../images/new/survey.svg';
import img_subscription from '../images/new/subscription.svg';
import img_job_interview from '../images/new/job_interview.svg';
import img_leads from '../images/new/leads.svg';
import img_rsvp from '../images/new/rsvp.svg';
import img_calling from '../images/new/calling.svg';

const Usecases = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    const productCards = [
        {
            img: img_leads,
            title: "Smart Lead Qualifier",
            description: "Automate your lead generation with AI-driven voice agents that engage potential customers and categorize them as hot, warm, or cold leads for your sales team."
        },
        {
            img: img_appointment,
            title: "Appointment Manager",
            description: "AI voice bot streamlines appointment bookings, handles (re)scheduling and sends automated reminders, reducing no-shows and freeing staff for essential tasks."
        },
        {
            img: img_customer_support,
            title: "Automated Customer Support",
            description: "Enhance your customer service with AI voice bots that answer queries about order status, delivery dates, and more, reducing wait times and improving satisfaction."
        },
        {
            img: img_survey,
            title: "Voice-Based Surveys",
            description: "Conduct instant customer feedback surveys through AI-driven calls, collecting valuable insights and data without manual intervention."
        },
        {
            img: img_calling,
            title: "Virtual Receptionist",
            description: "Replace traditional receptionists with an AI-powered voice bot that greets callers, provides basic information, and directs them to the appropriate department."
        },
        {
            img: img_rsvp,
            title: "Event RSVP Bot",
            description: "Simplify event planning with an AI voice bot that calls invitees, confirms attendance, and updates your guest list in real time."
        },
        {
            img: img_subscription,
            title: "Subscription Renewal",
            description: "Retain more customers with an AI voice bot that reminds them of upcoming subscription renewals and offers options to renew or upgrade directly through the call."
        },
        {
            img: img_job_interview,
            title: "Automated HR Screener",
            description: "Voice AI coordinates interviews, confirms availability, and conducts initial screenings with pre-set questions, evaluating responses to streamline hiring."
        }
    ];

    return (
        <div id="usecases" className="bg-gray-100 py-12">
            <section data-aos="zoom-in-down">
                <div className="my-4 py-4">
                    <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">Use-cases</h2>
                    <div className='flex justify-center'>
                        <div className='w-24 border-b-4 border-blue-900'></div>
                    </div>
                    <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-blue-900">Explore potential use-cases for your business needs!</h2>
                </div>

                <div className="px-12" data-aos="fade-down" data-aos-delay="600">
                    <Slider {...settings}>
                        {productCards.map((card, index) => (
                            <div key={index} className="px-2">
                                <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group h-full flex flex-col">
                                    <div className="m-2 text-justify text-sm flex-grow flex flex-col">
                                        <div className="h-55 flex items-center justify-center overflow-hidden">
                                            <img alt="card img" className="w-32 h-32 object-contain group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={card.img} />
                                        </div>
                                        <h2 className="font-semibold my-4 text-2xl text-center">{card.title}</h2>
                                        <p className="text-md font-medium flex-grow">{card.description}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </section>
        </div>
    )
}

export default Usecases;