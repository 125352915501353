import React, { useState, useEffect } from 'react';

const SalesAgent = ({ HTTP_HOST }) => {
  const [prompt, setPrompt] = useState('xyz');
  const [file, setFile] = useState(null);
  const [fileError, setFileError] = useState('');
  const [isPromptChanged, setIsPromptChanged] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    setIsPromptChanged(prompt !== 'xyz');
  }, [prompt]);

  const handlePromptChange = (e) => {
    setPrompt(e.target.value);
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && selectedFile.size > 2 * 1024 * 1024) {
      setFileError('File size should be less than 2 MB');
      setFile(null);
    } else {
      setFileError('');
      setFile(selectedFile);
    }
  };

  const handleSave = async () => {
    setIsSaving(true);
    const formData = new FormData();
    formData.append('prompt', prompt);
    if (file) {
      formData.append('file', file);
    }

    try {
      const response = await fetch(`${HTTP_HOST}/insurance_sales_prompts`, {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Failed to save data');
      }

      // Handle successful save (e.g., show a success message)
      console.log('Data saved successfully');
    } catch (error) {
      console.error('Error saving data:', error);
      // Handle error (e.g., show an error message to the user)
    } finally {
      setIsSaving(false);
    }
  };

  // return (
  //   <div className="mt-6">
  //     <h2 className="text-2xl font-semibold mb-4">Insurance Sales Agent</h2>
  //     <div className="mb-4">
  //       <label htmlFor="prompt" className="block text-sm font-medium text-gray-700 mb-2">Prompt</label>
  //       <textarea
  //         id="prompt"
  //         value={prompt}
  //         onChange={handlePromptChange}
  //         rows="10"
  //         className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
  //         style={{ resize: 'vertical' }}
  //       />
  //     </div>
  //     <div className="mb-4">
  //       <label htmlFor="file-upload" className="block text-sm font-medium text-gray-700 mb-2">Upload File (max 2MB)</label>
  //       <div className="flex items-center">
  //         <input
  //           type="file"
  //           id="file-upload"
  //           onChange={handleFileChange}
  //           className="hidden"
  //         />
  //         <label
  //           htmlFor="file-upload"
  //           className="cursor-pointer bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-blue-600 hover:bg-gray-50"
  //         >
  //           Choose file
  //         </label>
  //         {file && <span className="ml-3 text-sm text-gray-500">{file.name}</span>}
  //       </div>
  //       {fileError && <p className="mt-2 text-sm text-red-600">{fileError}</p>}
  //     </div>
  //     <div>
  //       <button
  //         onClick={handleSave}
  //         disabled={!isPromptChanged || isSaving}
  //         className={`py-2 px-4 rounded-md text-white ${
  //           isPromptChanged && !isSaving
  //             ? 'bg-blue-600 hover:bg-blue-700'
  //             : 'bg-gray-400 cursor-not-allowed'
  //         }`}
  //       >
  //         {isSaving ? 'Saving...' : 'Save'}
  //       </button>
  //     </div>
  //   </div>
  // );
  return (
    <div>
      {/* Add any specific UI elements for generic chat here */}
      {/* <p>This is the generic chat demo. Ask me anything!</p> */}
    </div>
  );
};

export default SalesAgent;