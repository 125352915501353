import React, { useEffect, useState } from 'react';

const AppointmentScheduler = ({ HTTP_HOST }) => {
  const [key, setKey] = useState(0);
//   const calendarUrl = 'https://calendar.google.com/calendar/embed?height=600xxxxxx';
  const calendarUrl = 'https://calendar.google.com/calendar/embed?src=f0b43969fa69c3010425ac8cc25d7891bde0711c865d04ce8655371329b93b06%40group.calendar.google.com&ctz=Asia%2FSingapore'

  useEffect(() => {
    const checkCalendarUpdate = async () => {
      try {
        const response = await fetch(`${HTTP_HOST}/check_calendar_update`);
        if (!response.ok) {
          console.error('Failed to check calendar update');
          return;
        }
        const data = await response.json();
        console.log(`response of /check_calendar_update: ${JSON.stringify(data)}`);
        if (data.updated) {
          setKey(prevKey => prevKey + 1); // Refresh the iframe
        }
      } catch (error) {
        console.error('Error checking calendar update:', error);
      }
    };

    // Poll every 5 seconds (adjust as needed)
    const intervalId = setInterval(checkCalendarUpdate, 5000);

    return () => clearInterval(intervalId);
  }, [HTTP_HOST]);

  return (
    <div>
      {/* <p>Book your doctor appointment here!</p> */}
      <iframe
        key={key}
        id="gcal"
        style={{ border: 'solid 1px #777', width: '100%', height: '600px' }}
        frameBorder="0"
        scrolling="no"
        src={calendarUrl}
        title="Google Calendar"
      ></iframe>
    </div>
  );
};

export default AppointmentScheduler;